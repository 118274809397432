<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->
                                        
                    <!--indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show"/>   
                    <!--indication end-->

                    <!--information_general start-->
                    <InformationGeneral v-else-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->  

                    <!--Conclusion start-->
                    <Conclusion v-if="key == 'detail_Conclusion' && item.show" /> 
                    <!--Conclusion end-->
                    
                    <!-- detail_clinical_trials  -->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!--Key conclusion-->       
                    <div class="content-onglets table" v-else-if="key=='detail_key_conclusion' && item.show">
                        <p v-html="check_empty(data.hira['key_conclusion'])"></p>                                        
                    </div>
                    <!--Key conclusion-->

                    <!--Medical Need start-->
                    <div class="content-onglets table"  v-else-if="key=='detail_medical_need' && item.show">
                        <p v-html="check_empty(data.hira['medical_need'])"></p>                                        
                    </div>
                    <!--Medical Need-->

                    <!--efficacy start-->     
                    <div class="content-onglets table"  v-else-if="key=='detail_efficacy' && item.show">
                        <p v-html="check_empty(data.hira['efficacy'])"></p>                    
                    </div>            
                    <!--efficacy end-->

                    <!--safety start-->       
                    <div class="content-onglets table"  v-else-if="key=='detail_safety' && item.show">
                        <p v-html="check_empty(data.hira['safety'])"></p>
                    </div>   
                    <!--safety end-->

                    <!--Comparator start-->      
                    <div class="content-onglets table" v-else-if="key=='detail_comparator' && item.show"> 
                        <p v-html="data.hira['comparator']"></p> 
                        <table class="comparateur2 border-top-gray" v-if="data.hira['hira_comparator'].length > 0 ">
                            <tbody>
                                <tr>
                                    <th width="100%">Comparator</th>
                                </tr>                                
                                <tr v-for="(items,keys) in data.hira['hira_comparator']" :key="'hira_comparator'+keys">
                                    <td class="text-left">{{check_empty(items['comparator']) }}</td>
                                </tr>                                 
                            </tbody>
                        </table>        
                    </div> 
                    <!--Comparator end-->

                    <!--cost_effectiveness start-->       
                    <div class="content-onglets"  v-else-if="key=='detail_cost_effectiveness' && item.show"> 
                        <p v-html="check_empty(data.hira['cost_effectiveness']) "></p> 
                    </div>            
                    <!--cost_effectiveness end-->

                    <!--detail_financial_impact start-->    
                    <div class="content-onglets"  v-else-if="key=='detail_financial_impact' && item.show">                    
                        <p v-html="check_empty(data.hira['financial_impact']) "></p>
                    </div>
                    <!--detail_financial_impact end-->

                    <!--detail_reimbursement_decision start-->
                    <div class="content-onglets table"  v-else-if="key=='detail_reimbursement_decision' && item.show">
                        <table class="comparateur2 table text-left"> 
                            <tr v-if="data.hira['reimbursement_decision_type']">
                                <td class="bold bg-white" width="20%"><strong>Reimbursement decision</strong></td>
                                <td class="bg-white">{{ data.hira['reimbursement_decision_type']  }}</td>
                            </tr>
                            <tr v-if="data.hira['reimbursement_decision']">
                                <td class="bg-white" colspan="2"><p v-html="data.hira['reimbursement_decision']"></p></td>
                            </tr>
                            <tr v-if="data.hira['reimbursement_date']">
                                <td class="bold bg-white" width="20%"><strong>Reimbursement application date</strong></td>
                                <td class="bg-white">{{DDMMMYY( data.hira['reimbursement_date'] )}}</td>
                            </tr>                         
                            <tr v-if="data.hira['publication_date']">
                                <td class="bold bg-white" width="20%"><strong>Reimbursement publication date</strong></td>
                                <td class="bg-white">{{DDMMMYY( data.hira['publication_date'] )}}</td>
                            </tr>                        
                        </table>
                    </div>    
                    <!--detail_reimbursement_decision end-->

                    <!--comment start-->       
                    <div class="content-onglets"  v-else-if="key=='detail_comment' && item.show" >                    
                        <p v-html=" check_empty(data.hira['comment']) "></p>
                    </div>       
                    <!--comment end-->

                    <!--Key documents start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>        
                    <!--Key documents end-->

                   <!--@todo detail_EssaisClinique -->

                    
                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                     
                    <!-- Section Detail End -->

                </div>
            </template>
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import KeyDocument from '../key_document.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_empty, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'hira',
    components : {
        LeftSection,
        TreatmentLine,		 
        EconomicEvaluation,       
        Conclusion,
        LinkAgency,        
        KeyDocument,
        EssaisClinique,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_conclusion : {'title' : 'Key conclusion', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_medical_need : {'title' : 'Medical Need', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_efficacy : {'title' : 'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_safety : {'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_comparator : {'title' : 'Comparator', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_cost_effectiveness : {'title' : 'Cost-effectiveness', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_financial_impact : {'title' : 'Financial Impact', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_reimbursement_decision : {'title' : 'Reimbursement decision', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_comment : {'title' : 'comment_detail', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EssaisClinique : {'title' :'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation : {'title' :'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
                
            }
        }
    },
    created(){
        if(this.data && this.data.hira){
            
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }
            if (this.data['ma_date']){
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) ||
                this.data.hira['rationale_commentary'] ){                                    
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if (this.data.hira['key_conclusion']){
                this.list_check_box['detail_key_conclusion'].enable = true
            }
            if (this.data.hira['medical_need']){
                this.list_check_box['detail_medical_need'].enable = true
            }
            if (this.data.hira['efficacy']){
                this.list_check_box['detail_efficacy'].enable = true
            }
            if (this.data.hira['safety']){
                this.list_check_box['detail_safety'].enable = true
            }
            if (this.data.hira['comparator'] || this.data.hira['hira_comparator'].length > 0){
                this.list_check_box['detail_comparator'].enable = true
            }
            if (this.data.hira['cost_effectiveness']){
                this.list_check_box['detail_cost_effectiveness'].enable = true
            }
            if (this.data.hira['financial_impact']){
                this.list_check_box['detail_financial_impact'].enable = true
            }
            if (this.data.hira['reimbursement_decision_type'] || this.data.hira['reimbursement_decision'] || this.data.hira['reimbursement_date'] || this.data.hira['publication_date']){
                this.list_check_box['detail_reimbursement_decision'].enable = true
            }
            if (this.data.hira['comment']){
                this.list_check_box['detail_comment'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            
            // this.list_check_box['detail_EssaisClinique'].enable = true                
        }
    },
    methods: {
         DDMMMYY,
         check_empty,
         check_detail_conclusion,
         key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }    
    },
}
</script>

<style>

</style>